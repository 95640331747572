import { FeaturePage } from "../components/FeaturePage";
import React from 'react'

export default () => (
  <FeaturePage
    heading1="Organise all of your communication"
    heading2="Bring all of your emails, SMS, and CRM into one cloud-based tool."
    heading3="Manage customer communication within Trybe"
    headerBlocks={[
      "Engage with your customers through all channels within one platform",
      "Personalise every step of the journey by creating the content and design for each communication",
      "Streamline your confirmation and cancellation emails from one platform",
      "Automate your waitlist management for classes through SMS and Email",
    ]}
    featurePageBlocks={[
      {
        chip: 'Emails',
        heading: 'Bring all of your confirmation and cancellation emails into one place',
        body: [
          "All email communication will be attached to a customer booking for all members of the team to access when needed.",
        ]
      },
      {
        chip: 'SMS',
        heading: 'Manage and automate text messages',
        body: [
          "Ensure efficient waitlist management through SMS alerts and communication.",
        ],
      },
      {
        chip: 'Waitlist',
        heading: 'Manage your class waitlists without having to lift a finger',
        body: [
            "Cut down on hours spent managing class waitlists by enabling Trybe’s quick and easy SMS feature.",
        ]
      },
    ]}
  />
)
